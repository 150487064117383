import "./ViewFixture.css";
import { Button, Spinner } from "../../common";
import { ReactComponent as ArrowLeftIcon } from "../../../icons/arrow-left.svg";
import { useParams, useLocation, useHistory } from "react-router-dom";
import useRouterQuery from "../../../utils/useRouterQuery";
import { FIXTURE } from "../../../api/fixtures";
import { useQuery } from "@apollo/client";
import FixtureInfo from "../FixtureInfo";
import DeleteFixture from "../DeleteFixture";
import { useAppState } from "../../../utils/appState";
// import EventInfo from "../EventInfo";
// import EventRegistrations from "../EventRegistrations";
// import EventFixtures from "../EventFixtures";
// import UploadEventStats from "../UploadEventStats";

const tabs = [
  { key: "info", label: "Info", component: FixtureInfo },
  // {
  //   key: "registrations",
  //   label: "Registrations",
  //   component: EventRegistrations,
  // },
  // { key: "fixtures", label: "Fixtures", component: EventFixtures },
  // { key: "upload", label: "Upload Stats", component: UploadEventStats },
];

export default function ViewFixture() {
  const { id } = useParams();
  const [, setAppState] = useAppState();
  const history = useHistory();
  const location = useLocation();
  const query = useRouterQuery();
  const tab = query.get("t") || "info";
  const selectedTab = tabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
      event{
        id
      }
      completed
    label
    roles{
      id
      order
      type{
        id
        name
      }
      organization{
        id
        name
        handle
        profilePhoto{
          id
          filename
        }
      }
      placement{
        id
        order
        fixture{
          id
          label
        }
        eventPart{
          id
          label
        }
      }
    }
  `,
    }),
    {
      variables: { id },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const fixture = data?.fixture;
  const participants = fixture?.roles
    ?.filter((r) => r.type?.name === "PARTICIPANT")
    .sort((a, b) => {
      return a.order - b.order;
    });

  const homeTeam = participants?.[0]?.organization;
  const awayTeam = participants?.[1]?.organization;

  return (
    <div className="App-view-page">
      <div className="App-view-page__header">
        <div
          className="App-view-page__header__back-btn"
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowLeftIcon className="App-view-page__header__back-btn__icon" />
        </div>
        <div
          className="App-view-page__header__title"
          style={{ fontSize: "16px" }}
        >
          {homeTeam?.name}{" "}
          <span
            style={{
              fontSize: "10px",
              color: "var(--dark-4)",
              marginLeft: "8px",
              marginRight: "8px",
            }}
          >
            vs
          </span>{" "}
          {awayTeam?.name}
        </div>
        <div className="App-view-page__header__options">
          {!fixture.completed && (
            <Button
              label={"DELETE FIXTURE"}
              className="App-view-page__header__options__delete"
              onClick={() => {
                setAppState({
                  modal: <DeleteFixture id={id} ev={fixture?.event} />,
                });
              }}
            />
          )}
        </div>
      </div>

      <div className="App-view-page__tabs">
        {tabs.map((t, i) => (
          <div
            key={"event-tab-" + i}
            className={
              "App-view-page__tabs__item " +
              (selectedTab.key === t.key ? "selected" : "")
            }
            onClick={() => {
              history.replace(location.pathname + "?t=" + t.key);
            }}
          >
            {t.label}
          </div>
        ))}
      </div>

      <div className="App-view-page__body">
        <SelectedComponent fixture={fixture} />
      </div>
    </div>
  );
}
